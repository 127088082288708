import * as React from "react";
import Layout from "../components/Layout/Layout";
import Undefined from "../images/undefined_icon.svg";
import { injectIntl } from "gatsby-plugin-intl";

//@ts-ignore
const prices = ({ intl }) => {
  return (
    <Layout>
      <div className="flex flex-col  items-center text-center mb-16 font-[gabdugi]  ">
        <img src={Undefined} alt="" />
        <p className="font-bold text-[#DA6A00] text-4xl">
          {intl.formatMessage({ id: "Error.ErrorTitle" })}
        </p>
        <p className="text-2xl">
          {intl.formatMessage({ id: "Error.PageNotFoundText" })}
        </p>
        <p className="text-[#DA6A00] underline text-xl cursor-pointer">
          {intl.formatMessage({ id: "Error.GoBackToMainPage" })}
        </p>
      </div>
    </Layout>
  );
};

export default injectIntl(prices);
